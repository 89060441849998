import { Education } from "../Education/Education";
import { Employment } from "../Employment/Employment";
import { Skills } from "../Skills/Skills";
import "./resume.scss";

export const Resume = () => {
  return (
    <section id="resume" className="container section">
      <Education />
      <Employment />
      <Skills />
    </section>
  );
};
