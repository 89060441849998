import React from "react";
import "./my-projects.scss";

export const MyProjects = () => {
  const projects = [
    {
      id: 1,
      name: "Learning platform and Course builder",
      text: "Our course builder lets you create your own courses using your proprietary content. Engage your learners with interactive elements like quizzes and videos.",
      image: "./images/udemy-full-2.png",
      tags: [
        "react",
        "javascript",
        "html",
        "css",
        "typescript",
        "scss",
        "Redux",
        "Nest.JS",
        "Node.JS",
        "PostgreSQL",
      ],
      website: "https://frontends.kz",
    },
    {
      id: 1,
      name: "Solution for mid-sized Airlines",
      text: "Say farewell to piecing together different vendors, integrations, and clunky software. Farel delivers one seamless, all-in-one, software platform engineered for growing airlines.",
      image: "./images/farel-laptop.png",
      tags: [
        "React",
        "angular",
        "javascript",
        "html",
        "css",
        "typescript",
        "scss",
        "Redux",
        "python",
        "Django",
        "PostgreSQL",
      ],
      website: "https://farel.io",
    },
    {
      id: 2,
      name: "UAE E-Wallet app",
      text: "Pay your bills and top-up the favorite services easy. Over +100 services over the world",
      image: "./images/ntwallet-laptop.png",
      tags: [
        "react",
        "javascript",
        "html",
        "css",
        "typescript",
        "scss",
        "Redux",
        "NgRX",
        "PostgreSQL",
      ],
      website: "https://ntwallet.ae",
    },
  ];
  return (
    <section id="portfolio" className="container section">
      <div className="row">
        <div className="col-md-12">
          <h2 id="portfolio_header" className="section__title">
            My projects_
          </h2>
        </div>
      </div>
      <div className="portfolio-cards">
        {projects.map((p) => {
          return (
            <div
              key={p.id}
              className="row project-card"
              data-toggle="modal"
              data-target="#portfolioModal"
              data-portfolio-tag="web-sites"
            >
              <div className="col-md-6 col-lg-5 project-card__img">
                <img className="" src={p.image} alt="project-img" />
              </div>
              <div className="col-md-6 col-lg-7 project-card__info">
                <h3 className="project-card__title">{p.name}</h3>
                <p className="project-card__description">{p.text}</p>
                <p className="project-card__stack">Used stack:</p>
                <ul className="tags">
                  {p.tags.map((t, index) => {
                    return <li key={index}>{t}</li>;
                  })}
                </ul>
                <a
                  href={p.website}
                  target="_blank"
                  className="project-card__link"
                >
                  {p.website}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
