import ReactDOM from "react-dom/client";
import App from "./App";
import "./scss/_normalize.scss";
import "./scss/_resets.scss";
import "bootstrap/dist/css/bootstrap.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
