import "./style.scss";

export const ContactList = () => {
  return (
    <div className="contacts__list">
      <dl className="contact-list">
        <dt>Phone:</dt>
        <dd>
          <a href="tel:+77772001991">+7 (777) 200-19-91</a>
        </dd>
        <dt>Skype:</dt>
        <dd>
          <a href="skype:serik.shaikamalov">serik.shaikamalov</a>
        </dd>
        <dt>Email:</dt>
        <dd>
          <a href="serik.shaikamalov@gmail.com">serik.shaikamalov@gmail.com</a>
        </dd>
      </dl>
    </div>
  );
};
