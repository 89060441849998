import React from "react";

export const Education = () => {
  return (
    <div className="row">
      <div className="col-md-8 section__resume resume-list">
        <h3 className="resume-list_title">education</h3>
        <div className="resume-list__block">
          <p className="resume-list__block-title">
            Kazakh-British Technical University
          </p>
          <p className="resume-list__block-date">2016 - 2017</p>
          <p>Master of Science - MS, Information Systems</p>
        </div>
        <div className="resume-list__block">
          <p className="resume-list__block-title">
            International Information Technologies University
          </p>
          <p className="resume-list__block-date">2009 - 2013</p>
          <p>
            Bachelor of Science - BS, Computer Science and Software Engineer
          </p>
        </div>
      </div>
    </div>
  );
};
