import "./style.scss";

export const ContactForm = () => {
  return (
    <div className="contacts__form">
      <p className="contacts__form-title">Or just write me a letter here_</p>
      <form className="js-form">
        <div className="form-group">
          <input
            className="form-field js-field-name"
            type="text"
            placeholder="Your name"
            required=""
          />
          <span className="form-validation"></span>
          <span className="form-invalid-icon">
            <i className="mdi mdi-close" aria-hidden="true"></i>
          </span>
        </div>
        <div className="form-group">
          <input
            className="form-field js-field-email"
            type="email"
            placeholder="Your e-mail"
            required=""
          />
          <span className="form-validation"></span>
          <span className="form-invalid-icon">
            <i className="mdi mdi-close" aria-hidden="true"></i>
          </span>
        </div>
        <div className="form-group">
          <textarea
            className="form-field js-field-message"
            placeholder="Type the message here"
            required=""
          ></textarea>
          <span className="form-validation"></span>
          <span className="form-invalid-icon">
            <i className="mdi mdi-close" aria-hidden="true"></i>
          </span>
        </div>
        <button className="site-btn site-btn--form" type="submit" value="Send">
          Send
        </button>
      </form>
    </div>
  );
};
