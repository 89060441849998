import "./contact-social.scss";

export const ContactSocial = () => {
  return (
    <div className="contacts__social">
      <ul>
        <li>
          <a href="https://www.facebook.com/serik.shaikamalov/">Facebook</a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/serik-shaikamalov/">Linkedin</a>
        </li>
        <li>
          <a href="https://github.com/serikshaikamalov">GitHub</a>
        </li>
      </ul>
    </div>
  );
};
