import "./menu.scss";

export const DesktopMenu = ({ menu, open }) => {
  return (
    <div className="menu">
      <div className="container">
        <div className="row">
          <div className="menu__wrapper d-none d-lg-block col-md-12">
            <nav>
              <ul>
                {menu.map((i) => (
                  <li key={i.name}>
                    <a href={i.path}>{i.name}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="menu__wrapper col-md-12 d-lg-none">
            <button type="button" className="menu__mobile-button" onClick={open}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  height={20}
                >
                  <path
                    style={{ fill: "#fff" }}
                    d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
