import "./blog.scss";

export const Blog = () => {
  const posts = [
    {
      name: "How to create advanced Quiz app.",
      text: "QuizApp - from scratch. React.JS, Nest.JS and for database used PostgreSQL",
      date: "October 01, 2022",
      image: "./images/img_blog_1.png",
    },
    {
      name: "How to use css-preprocessor",
      text: "Advanced technique for making an flexiable",
      date: "Sept 22, 2021",
      image: "./images/img_blog_2.png",
    },
    {
      name: "How I build course builder",
      text: "Roled based course builder and smart learning platform.",
      date: "Nov 01, 2022",
      image: "./images/img_blog_3.png",
    },
  ];
  return (
    <section id="blog" className="container section">
      <div className="row">
        <div className="col-md-12">
          <h2 id="blog_header" className="section__title">
            Latest Posts_
          </h2>
        </div>
      </div>

      <div className="row post-cards">
        {posts.map((p, index) => {
          return (
            <div className="col-md-4" key={index}>
              <a href="blog.html">
                <div className="post-cards__card">
                  <div className="post-cards__img">
                    <img src={p.image} alt="blog_img" />
                  </div>
                  <div className="post-cards__info">
                    <p className="post-cards__date">{p.date}</p>
                    <h3 className="post-cards_title">{p.name}</h3>
                    <p className="post-cards_description">{p.text}</p>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};
