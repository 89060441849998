import { ContactForm } from "../ContactForm/ContactForm";
import { ContactList } from "../ContactList/ContactList";
import { ContactSocial } from "../ContactSocial/ContactSocial";
import { Footer } from "../Footer/Footer";
import "./contact.scss";

export const Contact = () => {
  //   const style = { backgroundImage: "url(./images/img_bg_main.jpeg)" };

  return (
    <div className="background">
      <div id="contact" className="container section">
        <div className="row">
          <div className="col-md-12">
            <p id="contacts_header" className="section__title">
              Get in touch_
            </p>
          </div>
        </div>
        <div className="row contacts">
          <div className="col-md-5 col-lg-4">
            <ContactList />
            <ContactSocial />
          </div>
          <div className="col-md-7 col-lg-5">
            <ContactForm />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
