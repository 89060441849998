import React from "react";

export const Employment = () => {
  const jobs = [
    {
      company: "Codemotion",
      duration: "Feb 2022 - Current",
      position: "Senior Frontend Developer",
      responsibilities: [
        "Implemented Financial Trading app",
        "Development, support, and maintenance of web services APIs",
        "Find and help fix stability, performance issues, cross-browser compatibility, web standards.",
      ],
      tools:
        "React, HTML5, CSS, Typescript, Javascript, Shared components, Storybooks, GitLab CI/CD, Code Flow, Git, Unix/Linux",
    },
    {
      company: "Farel (YC S20)",
      duration: "Oct 2020 - Jan 2022",
      position: "Senior Software Engineer",
      responsibilities: [
        "Implemented websites, mobile applications, and landing pages from concept through deployment.",
        "Standardized all output with a new, responsive, mobile-first approach and strategy. Assessed UX and UI designs for technical feasibility.",
        "Perform code reviews.",
        "Mentor junior developers.",
        "Worked in Scrum process attending daily stand up and completing tasks in sprints",
        "Find and help fix stability, performance issues, cross-browser compatibility, web standards and memory leaks",
      ],
      tools:
        "Language/Tools: React, Angular, HTML5, CSS, Typescript, Javascript, Python, Shared components, GitLab CI/CD, Code Flow, Git, Unix/Linux",
    },
    {
      company: "NT.Payments (Kiosk IT System Trading LLC)",
      duration: "Apr 2018 - Oct 2020",
      position: "Senior Software Engineer",
      responsibilities: [
        "Implemented websites, mobile applications, and landing pages from concept through deployment.",
        "Development, support, and maintenance of web services APIs",
        "Implemented mobile Applications(Flutter, Dart, Cordova)",
        "Find and help fix stability, performance issues, cross-browser compatibility, web standards.",
        "Continuous integration and continuous delivery(DevOps)",
        "In application used state Managements like Redux, NgRX and NgXS",
        "As DevOps model include coding, testing and deploy the features tp Azure Cloud System",
      ],
      tools: "",
    },
    {
      company:
        "The Institute of Engineering and Information Technologies of the Kazakh-British Technical University",
      duration: "Sep 2016 - Apr 2018",
      position: "Middle+ Software Engineer",
      responsibilities: [
        "Involved in writing application level code to interact with Web Services. ",
        "Improve and add new features for the internal portal to view the status of services and monitors using ASP.Net and Angular",
        "Testing and debugging",
        "Find and help fix stability, performance issues, cross-browser compatibility, web standards.",
        "Find and help fix stability, performance issues, cross-browser compatibility, web standards.",
      ],
      tools:
        "Angular, HTML5, CSS3, Redux, Typescript, Javascript, MSSQL, C#, Asp.net",
    },
    {
      company: "Kazbim Information Technology",
      duration: "Jan 2015 - Aug 2016",
      position: "Software Engineer",
      responsibilities: [
        "Implemented the company website using HTML, CSS, Javascript.",
      ],
      tools: "HTML5, CSS3, Javascript, MySQL",
    },
    {
      company: "Bilim Media Group",
      duration: "May 2013 - Nov 2014",
      position: "Software Engineer",
      responsibilities: [
        "Implemented websites(www.kitap.kz, www.bilimland.kz) from concept through deployment",
        "Development, support, and maintenance of web services APIs",
        "Fixed bugs",
        "Optimized web application’s performance",
      ],
      tools: "HTML5, CSS3, Javascript, MySQL",
      metrics: [
        "Online book library in Kazakhstan.",
        "Used more than 1000 schools in Kazakhstan",
        "Online test platform itest.kz",
        "All school subjects in online format.",
        "3M+ active users around Kazakhstan",
      ],
    },
  ];

  return (
    <div className="row">
      <div className="col-md-8 section__resume resume-list">
        <h3 className="resume-list_title">employment</h3>
        {jobs.map((i) => {
          return (
            <div className="resume-list__block">
              <p className="resume-list__block-title">{i.company}</p>
              <p className="resume-list__block-date">{i.duration}</p>
              <p>{i.position}</p>
              <p>
                <ul>
                  {i.responsibilities.map((x) => {
                    return <li>{x}</li>;
                  })}
                </ul>
              </p>
              <p>Language/Tools: {i.tools}</p>
              <p>
                Project metrics and key features:{" "}
                <ul>
                  {i?.metrics?.map((x) => {
                    return <li>{x}</li>;
                  })}
                </ul>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
