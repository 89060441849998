import "./skills.scss";

export const Skills = () => {
  const skills = [
    {
      name: "html5",
      percentage: 80,
    },
    {
      name: "css3",
      percentage: 90,
    },
    {
      name: "Javascript",
      percentage: 100,
    },
    {
      name: "react.js",
      percentage: 90,
    },
    {
      name: "typescript",
      percentage: 90,
    },
    {
      name: "node.js",
      percentage: 80,
    },
    {
      name: "git",
      percentage: 90,
    },
    {
      name: "python",
      percentage: 80,
    },
    {
      name: "PostgreSQL",
      percentage: 80
    },
    {
      name: "Flutter/Dart",
      percentage: 70
    }
  ];
  return (
    <div className="row section__resume progress-list js-progress-list">
      <div className="col-md-12">
        <h3 className="progress-list__title">general skills</h3>
      </div>

      <div className="skills">
        {skills.map((skill) => {
          return (
            <div className="progress-list__skill" key={skill.name}>
              <p>
                <span className="progress-list__skill-title">{skill.name}</span>
                <span className="progress-list__skill-value">
                  {skill.percentage}%
                </span>
              </p>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: skill.percentage + "%" }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
