import { Header } from "./components/Header/Header";
import "./app.scss";
import { Hi } from "./components/Hi/Hi";
import { Resume } from "./components/Resume/Resume";
import { MyProjects } from "./components/MyProjects/MyProjects";
import { Blog } from "./components/Blog/Blog";
import { Contact } from "./components/Contact/Contact";
import { Menu } from "./components/Menu/Menu";

function App() {
  return (
    <div>
      <Menu />
      <Header />
      <Hi />
      <Resume />
      <MyProjects />
      <Blog />
      <Contact />
    </div>
  );
}

export default App;
