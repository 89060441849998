import { useRef } from "react";
import { DesktopMenu } from "../DesktopMenu/DesktopMenu";
import { MobileMenu } from "../MobileMenu/MobileMenu";

export const Menu = () => {
  const mobileMenuRef = useRef();

  const menu = [
    {
      name: "Hello",
      path: "#hello",
    },
    {
      name: "Resume",
      path: "#resume",
    },
    {
      name: "Portfolio",
      path: "#portfolio",
    },
    {
      name: "Blog",
      path: "#blog",
    },
    {
      name: "Contact",
      path: "#contact",
    },
  ];

  const toggle = () => {
    mobileMenuRef.current.classList.toggle("active");
  };

  return (
    <>
      <DesktopMenu menu={menu} open={toggle} />
      <MobileMenu menu={menu} close={toggle} mobileMenuRef={mobileMenuRef} />
    </>
  );
};
