import React from "react";

export const Hi = () => {
  return (
    <section id="hello" className="container section">
      <div className="row">
        <div className="col-md-10">
          <h2 id="hello_header" className="section__title">
            Hi_
          </h2>
          <p className="section__description">
            Solutions-focused and goal-oriented software engineer with eight
            years of experience in implementing advanced technology and business
            solutions in public and private industries. Demonstrates strong
            analytical and problem-solving skills and the ability to follow
            through with projects from inception to completion.
          </p>
          <a href="#" className="section_btn site-btn">
            <img src="./images/img_btn_icon.png" alt="" />
            Download CV
          </a>
        </div>
      </div>
    </section>
  );
};
